<template>
  <div>
    <div><strong>Subject</strong></div>
    <div class="p-3 bg-light">{{subject}}</div>

    <div class="mt-3"><strong>Body</strong></div>
    <div class="p-3 bg-light" v-html="body"></div>
  </div>
</template>

<script>
export default {
  props: ['translation'],
  components: {

  },

  data(){
    return {
      subject: null,
      body: null
    }
  },

  mounted(){
    let {subject,body} = this.translation.contents || {};

    this.subject = subject;
    this.body = body;
  }
}
</script>
