<template>
  <div v-if="!table.isLoading">
    <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
      <template #empty>
        no records
      </template>

      <template #cell(isTranslated)="data">
        <BIconCircleFill v-if="data.item.translation" variant="success"/>
        <BIconCircleFill v-else variant="danger"/>
      </template>
      <template #cell(options)="data">
        <div class="text-right">
          <b-link :to='{name: "emails.templates.template.translation", params: {templateId: template.id, locale: data.item.locale}}'><BIconPencil/></b-link>
          <b-link @click="openModal(data.item)" class="ml-3"><BIconInfoCircle/></b-link>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalShowTranslation" id="modal-show-translation" :title="selectedTranslation.title" hide-footer>
      <TranslationLocaleShow v-if="selectedTranslation" :translation="selectedTranslation"/>
    </b-modal>
  </div>
  <b-spinner v-else label="Loading..." small></b-spinner>
</template>

<script>
import {BIconPencil,BIconCircleFill,BIconInfoCircle} from 'bootstrap-vue';
import TranslationLocaleShow from './Template.Translation.Locale.Show';
export default {
  props: ['template'],
  components: {
    BIconPencil,BIconCircleFill,BIconInfoCircle,
    TranslationLocaleShow
  },
  data() {
    return {
      languages: [],
      translations: [],
      selectedTranslation: {
        title: null,
        locale: null,
        contents: null,
        template: null
      },
      table: {
        isLoading: false,
        records: [],
        pagination: {
          currentPageNo: 1
        },
        fields: [
          {
            key: 'name',
            label: "Language"
          },
          {
            key: 'isTranslated',
            label: "Translated"
          },
          {
            key: 'options',
            label: ""
          },
        ]
      }
    }
  },

  methods: {
    openModal(item){
      let lang = this.languages.find(o => o.locale === item.locale);
      this.selectedTranslation = {
        title: lang.name,
        locale: item.locale,
        template: this.template,
        contents: this.translations.find(o => o.locale === item.locale)
      };
      this.$refs['modalShowTranslation'].show();
    },
    closeModal(){
      this.$refs['modalShowTranslation'].hide();
      this.selectedTranslation = {
        title: null,
        locale: null,
        contents: null,
        template: null
      };
      this.init();
    },
    getLanguages(){
      return this.$api.get('translations/languages',{
        params: {
          perPage: 50
        }
      }).then((res) => {
        this.languages = res.data.records;
      })
    },

    getTranslations(){
      this.table.isLoading = true;
      return this.$api.get(`emails/template/${this.template.id}/translations`).then((res) => {
        this.translations = res.data;
        this.table.isLoading = false;
      })
    },

    setTable(){
      this.table.records = this.languages.map(item => {
        item.translation = this.translations.find(o => o.locale === item.locale);
        return item;
      });
    },

    init(){
      Promise.all([
        this.getLanguages(),
        this.getTranslations()
      ]).then(() => {
        this.setTable();
      });
    }
  },

  mounted(){
    this.init()
  }

}
</script>
